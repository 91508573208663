/* ========================================================================
 * DOM-based Routing
 * Based on https://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        //Publicidade

        $('.jc-navbar-tab-home a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
          var tab = $(this).attr('href');
          var format = 'hmeio1';

          switch(tab) {
            case '#news':
              format = 'hmeio1';
              break;
            case '#tabelajc':
              format = 'hmeio2';
              break;
          }
          $('#div-gpt-ad-2585995-1').empty();

          DFP.renderHtml({
            'selector':'#div-gpt-ad-2585995-1',
            'targets':{
              'formato': 'htopo'
            },
            'screenmap':'Horizontal_728_N_T_250'
          });

          $('#div-gpt-ad-2585995-2').empty();

          DFP.renderHtml({
            'selector':'#div-gpt-ad-2585995-2',
            'targets':{
              'formato': format
            },
            'screenmap':'Horizontal_728_N_T_250'
          });

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

$=jQuery;

function toggleDropdown (e) {
  if($(window).width()<1024) return;
  const _d = $(e.target).closest('.dropdown'),
    _m = $('.dropdown-menu', _d);
  setTimeout(function(){
    const shouldOpen = e.type !== 'click' && _d.is(':hover');
    _m.toggleClass('show', shouldOpen);
    _d.toggleClass('show', shouldOpen);
    $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
  }, e.type === 'mouseleave' ? 0 : 0);
}

$('body')
  .on('mouseenter mouseleave','.jc-dropdown',toggleDropdown)
  .on('click', '.dropdown-menu a', toggleDropdown)
  .on('click', '#menu-primary_navigation a', function(e){
    window.location.href = $(e.target).attr('href');
  })
